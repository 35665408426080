import React, { useState } from "react";
import { Stack } from "@mui/material";
import NumberInput from "./NumberInput";

export interface Set {
  set: {
    score1: number;
    score2: number;
  };
}

export default function ScoreInput({ set }: Set) {
  return (
    <Stack direction="row" spacing={1}>
      <div>
        <NumberInput num={set.score1} />
      </div>
      <div>:</div>
      <div>
        <NumberInput num={set.score2} />
      </div>
    </Stack>
  );
}
