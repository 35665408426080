import * as React from "react";
import { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import SetGroup from "./SetGroup";

export default function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  function onChangeHandler(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    var newValue = event.target.value;
    if (newValue.length > 1) {
      newValue = newValue[1];
    }
    newValue = newValue.replace(/[^0-7]/g, "");
    event.target.value = newValue;
  }

  const [players, setPlayers] = useState([
    {
      name: "Vlad",
      id: 0,
    },
    {
      name: "Sergey",
      id: 1,
    },
    {
      name: "Pavel",
      id: 2,
    },
    {
      name: "Paula",
      id: 3,
    },
  ]);

  const [sets, setScores] = useState([{ score1: 4, score2: 1 }, { score1: 6, score2: 3 }]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm">
        <SetGroup players={players} sets={sets} />
        <div className="my-4">
          <Button variant="outlined" startIcon={<Add />}>
            Played with another team
          </Button>
        </div>
        <div className="my-4">
          <Button variant="contained">Submit results</Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}
