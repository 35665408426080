import React from "react";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  Stack,
} from "@mui/material";

import ScoreInputGroup from "./ScoreInputGroup";

export interface PlayerItem {
  id: number;
  name: string
}

export interface SetItem {
  score1: number;
  score2: number;
}

export interface Props {
  players: PlayerItem[];
  sets: SetItem[];
}

export default function SetGroup({ players, sets }: Props) {
  const listPlayersLabels = players.map((player) => (
    <FormControlLabel
      value={player.id}
      control={<Radio />}
      label={player.name}
      key={player.id.toString()}
    />
  ));

  return (
    <div>
      <div className="my-4">
        <FormControl>
          <FormLabel id="players-group-label">I played with</FormLabel>
          <RadioGroup
            aria-labelledby="players-group-label"
            name="radio-buttons-group"
          >
            {listPlayersLabels}
          </RadioGroup>
        </FormControl>
      </div>
      <div className="my-4">
        <FormControl>
          <FormLabel id="other-group-label">Scores (my team first)</FormLabel>
          <ScoreInputGroup sets={sets} />
        </FormControl>
      </div>
    </div>
  );
}
