import React, { useState } from "react";
import { TextField } from "@mui/material";

export interface Props {
  num: number;
}

export default function NumberInput({ num }: Props) {
  const style = [
    {
      width: 24,
    },
    { "& input[type=number]": { "-moz-appearance": "textfield" } },
    {
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    {
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  ] as const;

  function onChangeHandler(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    console.log('change');
    var newValue = event.target.value;
    newValue = newValue.replace(/[^0-7]/g, "");
    if (newValue.length > 1) {
      newValue = newValue[1];
    }
    event.target.value = newValue;
  }

  return (
    <TextField
      variant="standard"
      sx={style}
      onChange={onChangeHandler}
      type="number"
      value={num}
    />
  );
}
