import React, { useState } from "react";
import { Stack } from "@mui/material";
import ScoreInput from "./ScoreInput";

export interface SetItem {
  score1: number;
  score2: number;
}

export interface Props {
  sets: SetItem[];
}

export default function ScoreInputGroup({ sets }: Props) {
  const listScoreInputs = sets.map((set) => (
      <ScoreInput set={set} />
  ))

  return <>{listScoreInputs}</>;
}
